import './App.css';

function Error() {




  return (
    <div className="ErrorPage">
      Something went wrong. Please contact the admin team.
    </div>
  );
}

export default Error;
