import './App.css';
import Pharmacy from './Pharmacy';
import {useSearchParams} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Error from './Error';

function App() {
  const [uuid, setUuid] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
    useEffect(()=>{
      if (searchParams.get("uuid") !== null && searchParams.get("uuid").length !==0){
        setUuid(<Pharmacy/>)
      }
      else{
        setUuid(<Error/>)
      }
      console.log(searchParams.get("uuid"));  // url is assumed as https://.....com?samplekey="dummy"
    })


  return (
    <div className="App">
     {uuid}
    </div>
  );
}

export default App;
